import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element = {<Layout />}>
        <Route index element={<Home />}/>
        <Route path= "/projects" element = {<Projects />}/>
        <Route path= "/about" element = {<About/>}/>
        <Route path= "/contact" element = {<Contact/>}/>

        </Route>  
      
      
      {/* <Route path= "/projects" element = {<Layout />}/> */}
     


    </Routes>
    </>
  );
}

export default App;
