import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LogoS from '../../assets/images/logo-s.png'
import amazon from '../../assets/images/amazon.png'
import Medisense from '../../assets/images/Medisense.png'
import Emotify from '../../assets/images/Emotify.png'
import './index.scss'



class DemoCarousel extends Component {
    render() {
        return (
            <Carousel>
                <div>
                    <img src={Medisense} alt=" 1" />
                    <p className="legend">Using a random forest classifier model, Medisense calculates the risk for a patient for having a certain disease. Doctors can use this tool to confirm a diagnosis using a patient's information, which will be compared to other patients who have similar symptoms. </p>
                </div>
                <div>
                    <img src={Emotify} alt="Item 2" />
                    <p className="legend">Emotify generates playlists for a listener who has a specific mood that they want to listen to. Using ChatGPT, Emotify can return the best songs that corroborate with a user's request, allowing for a specific and unique listening experience!</p>
                </div>
                <div>
                    <img src={amazon} alt="Item 3" />
                    <p className="legend">In my "Amazon Model" project, I replicated Amazon's core functionalities using C++. I focused on implementing features like adding and deleting users, managing a functional shopping cart using data structures, and enhancing the product search experience with an optimized search algorithm. This endeavor allowed me to deepen my understanding of C++, data structures, and algorithmic efficiency, mirroring the sophisticated processes of a leading e-commerce platform.</p>
                </div>
            </Carousel>
        );
    }
}

const Projects = () => {
    return (
        <div className="container">
            <div className="text-zone">
                <DemoCarousel />
            </div>
        </div>
    );
};

export default Projects;
