import "./index.scss";
import aboutme from '../../assets/images/aboutme.jpg';

const About = () => {
    return (
        <div className="container about-page">
            <img className="abtimg"src={aboutme} alt="About Me"/>
            <div className="text-zone">
                <h1>About Me</h1>
        
                <h2>
                    I'm Ashwin Sanjaye, a dedicated and ambitious Computer Science & Business Administration student at USC, graduating in May 2025. My academic journey, enriched with courses like <span class="csba">Discrete Mathematics</span>, <span class="csba">C++ Programming</span>, and <span class="csba">Machine Learning</span>, has honed my analytical and technical skills. I bring a diverse set of technical competencies, including proficiency in <span class="csba">C++</span>, <span class="csba">Python</span>, <span class="csba">Java</span>, <span class="csba">Flask</span>, and <span class="csba">React</span>. My professional experiences as an intern have equipped me with robust skills in <span class="csba">front-end development</span>, <span class="csba">API integration</span>, and <span class="csba">backend connectivity</span>. I'm particularly proud of my involvement in projects like <span class="csba"><a href="/projects" className="flat-button2">Emotify</a></span> and <span class="csba"><a href="/projects" className="flat-button2">Medisense</a></span>, where I've successfully integrated advanced technology to create user-centric solutions. Committed to continuous learning and innovation, I am eager to contribute my expertise to the dynamic world of technology. Visit my <a href="https://www.linkedin.com/in/ashwin-sanjaye/" target="_blank" rel="noopener noreferrer" className="flat-button2">LinkedIn</a>, <a href="https://github.com/asanjaye" target="_blank" rel="noopener noreferrer" className="flat-button2">Github</a>, or <a href="https://drive.google.com/file/d/12JN-KUKQHcvSJ9blevucabq2fsSXuWAm/view?usp=sharing" className="flat-button2" target="_blank">resumé</a> to learn more about me!
                </h2>
                <br/><br/><br/>
            </div>
        </div>
    );
}

export default About;
