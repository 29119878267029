// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  width: 100%;
  height: 100%;
  height: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AACJ","sourcesContent":[".page{\n    width: 100%;\n    height: 100%;\n    height: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
