import {Link, NavLink} from 'react-router-dom'
import "./index.scss"
import LogoS from '../../assets/images/logo-s.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome, faUser  } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Sidebar = ()  => (
    <div className="nav-bar">
        <Link className = 'logo' to="/">
            <img src={LogoS} alt = "logo" />
            {/* <img src = {LogoSubtitle} alt = "ashwin"/> */}
        </Link>
        <nav>
            <NavLink exact = "true" activeclassname="active" to="/">
                <FontAwesomeIcon icon = {faHome} color='#e6e6ea'/>
            </NavLink>
            <NavLink exact = "true" activeclassname="active" className = "about-link" to="/about">
                <FontAwesomeIcon icon = {faUser} color='#e6e6ea'/>
            </NavLink>
            <NavLink exact = "true" activeclassname="active" className = "contact-link"to="/contact">
                <FontAwesomeIcon icon = {faEnvelope} color='#e6e6ea'/>
            </NavLink>
        </nav>
        <ul>
            <li>
                <a
                    target = "_blank"
                    rel = "noreferrer"
                    href = "https://www.linkedin.com/in/ashwin-sanjaye/">
                <FontAwesomeIcon icon = {faLinkedin} color ='#e6e6ea' />

                </a>
            </li>
            <li>
                <a
                    target = "_blank"
                    rel = "noreferrer"
                    href = "https://github.com/asanjaye">
                <FontAwesomeIcon icon = {faGithub} color ='#e6e6ea' />

                </a>
            </li>
        </ul>
    </div>
)

export default Sidebar