// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html{
  font-size: 62.5%;
}

body {
  margin: 0;
  /* weight is 300, size is 11px */
  font-family: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background: #022c43;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,gCAAgC;EAChC,wDAAwD;EACxD,WAAW;EACX,mBAAmB;EACnB,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["\nhtml{\n  font-size: 62.5%;\n}\n\nbody {\n  margin: 0;\n  /* weight is 300, size is 11px */\n  font-family: 300 11px/1.4 'Helvetica Neue', 'sans-serif';\n  color: #444;\n  background: #022c43;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
