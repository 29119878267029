import { Link } from "react-router-dom";
import './index.scss'
const Home = () =>{
    return (
        <div className="container home-page">
            <div className = "text-zone">
                <h1>Hi, <br /> I'm Ashwin!</h1>
                <h2>I am a third year undergraduate student at USC, studying <span class="csba">Computer Science and Business Administration.</span> </h2>
                <br></br><br></br><br></br>                

                <Link to="/projects" className="flat-button">My Projects</Link>
                <Link to="/contact" className="flat-button">Contact Me</Link>
            </div>

        </div>
    );
}

export default Home