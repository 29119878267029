// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center; /* Center the child horizontally */
  align-items: center; /* Center the child vertically */
  height: 100vh; /* Adjust as per your layout needs */
}

.text-zone {
  animation: fadeIn 1s 0.7s backwards;
  max-width: 90%; /* Adjust the width as needed */
  padding: 30px;
}

.carousel .slide img {
  animation: fadeIn 1s 0.7s backwards;
  width: auto; /* Maintain aspect ratio */
  max-width: 80%; /* Ensure image is not larger than its container */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center the image */
}

.carousel .slide .legend {
  animation: fadeIn 1s 0.7s backwards;
  max-width: 100%;
  height: auto;
  font-size: 20px;
  font-family: "Coolvetica";
  color: 224;
}`, "",{"version":3,"sources":["webpack://./src/components/Projects/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA,EAAA,kCAAA;EACA,mBAAA,EAAA,gCAAA;EACA,aAAA,EAAA,oCAAA;AACF;;AAEA;EACE,mCAAA;EAEA,cAAA,EAAA,+BAAA;EACA,aAAA;AAAF;;AAGA;EACE,mCAAA;EAEA,WAAA,EAAA,0BAAA;EACA,cAAA,EAAA,kDAAA;EACA,YAAA,EAAA,0BAAA;EACA,cAAA;EACA,cAAA,EAAA,qBAAA;AADF;;AAIA;EACE,mCAAA;EACA,eAAA;EAEA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,UAAA;AAFF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center; /* Center the child horizontally */\n  align-items: center; /* Center the child vertically */\n  height: 100vh; /* Adjust as per your layout needs */\n}\n\n.text-zone {\n  animation: fadeIn 1s .7s backwards;\n\n  max-width: 90%; /* Adjust the width as needed */\n  padding: 30px;\n}\n\n.carousel .slide img {\n  animation: fadeIn 1s .7s backwards;\n\n  width: auto; /* Maintain aspect ratio */\n  max-width: 80%; /* Ensure image is not larger than its container */\n  height: auto; /* Maintain aspect ratio */\n  display: block;\n  margin: 0 auto; /* Center the image */\n}\n\n.carousel .slide .legend{\n  animation: fadeIn 1s .7s backwards;\n  max-width: 100%;\n  // width: 80%;\n  height: auto;\n  font-size: 20px;\n  font-family: 'Coolvetica';\n  color: red($color: #e00e0e);\n// left: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
