// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/animate.css/animate.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/helvetica-neu.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/LaBelleAurore.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/CoolveticaRg-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Helvetica Neue";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("ttf");
}
@font-face {
  font-family: "La Belle Aurore";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
}
@font-face {
  font-family: "Coolvetica";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
}
input,
textarea {
  font-family: "Courier New", Courier, monospace;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAGA;EACE,6BAAA;EACA,0DAAA;AADF;AAKA;EACE,8BAAA;EACA,4DAAA;AAHF;AAKA;EACE,yBAAA;EACA,4DAAA;AAHF;AAOA;;EAEE,8CAAA;AALF","sourcesContent":["$primary-color: #ffd700;\n@import 'animate.css';\n\n@font-face {\n  font-family: 'Helvetica Neue';\n  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');\n  // font-family: 'Courier New', Courier, monospace;\n}\n//link the assets file go ti 5:02\n@font-face {\n  font-family: 'La Belle Aurore';\n  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');\n}\n@font-face {\n  font-family: 'Coolvetica';\n  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');\n}\n\n//default input and text font\ninput,\ntextarea{\n  font-family:'Courier New', Courier, monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
