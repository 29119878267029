import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './index.scss';

const Contact = () => {
    const refForm = useRef()

    const sendEmail = (e) => {
        e.preventDefault(); // Correctly separate this line

        emailjs.sendForm('service_m0vk66f', 'template_qbenub8', refForm.current, '7cMSv_HihjONNrE1P')
            .then(() => {
                alert('Message successfully sent!');
                window.location.reload(false);
            }, (error) => {
                console.log(error)
                alert('Failed to send the message, please try again');
            });
    };

    return (
        <div className="container contact-page">
            <div className="text-zone2">
                <h1>Contact Me!</h1>
                <div className="contact-form">
                    <form ref={refForm} onSubmit={sendEmail}>
                        <input type="text" name="name" placeholder="Name" required></input>
                        <br></br>
                        <input type="email" name="email" placeholder="Email" required></input>
                        <br></br>
                        <input type="text" name="subject" placeholder="Subject" required></input>
                        <br></br>
                        <textarea name="message" placeholder="Message" required></textarea>
                        <br></br>
                        <button type="submit" className="flat-button1">SEND</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
